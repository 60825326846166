<template>
    <div class="view pa24">
        
        <el-tabs v-model="searchData.year" @tab-click="searchFun">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                :key="index"></el-tab-pane>
        </el-tabs>
        <el-tabs v-model="searchData.dataType" @tab-click="searchFun">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane :label="item" :name="String(id)" v-for="(item, id) in typeObj" :key="id"></el-tab-pane>
        </el-tabs>
        <div class="d-flex mt20">
            <div>
                <el-select v-model="searchData.isMonth" placeholder="请选择" class="w130 mr10" @change="searchFun">
                    <el-option v-for="(item, index) in listTypeData" :key="index" :label="item.label" :value="item.id">
                    </el-option>
                </el-select>
                <el-select class="w100 mr10" v-model="searchData.month" placeholder="请选择">
                    <el-option v-for="(item, index) in monthData" :key="index" :label="item.label"
                        :value="String(item.id)">
                    </el-option>
                </el-select>
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto;">
                <el-button type="primary" @click="uploadFilesVisible = true"
                    :disabled="!checkedCompany.pcId">导入{{ btnName }}</el-button>
                <el-button type="primary" @click="showAddModel" :disabled="!checkedCompany.pcId">录入{{ btnName }}</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" :pageSizeData="[10,30,50,100,200]" :showSummary="true" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total" v-show="showAllTable">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" width="90"/>
                <el-table-column align="center" label="日期" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        {{ scope.row.year + "年 " + scope.row.month + "月" }}
                    </template>
                </el-table-column>
                <el-table-column prop="dataTypeName" align="center" label="类型" show-overflow-tooltip />
                <el-table-column prop="voucherCode" align="center" label="凭证号" show-overflow-tooltip />
                <el-table-column prop="remark" align="center" label="摘要" show-overflow-tooltip />
                <el-table-column prop="subject" align="center" label="科目" show-overflow-tooltip />
                <el-table-column prop="voucherMoney" align="center" label="凭证金额" show-overflow-tooltip>

                    <template slot-scope="scope">
                        {{ scope.row.voucherMoney.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="researchMoney" align="center" label="计入研发费用金额" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.researchMoney.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="difference" align="center" label="凭证-研发费=差额" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.difference.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="150">

                    <template slot-scope="scope">
                        <el-button type="text" @click="showTransferFun(scope.row)">转移</el-button>
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button style="color:#F56C6C" type="text" @click="delData(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false" v-show="!showAllTable">

            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" width="90"/>
                <el-table-column prop="month" align="center" label="日期" show-overflow-tooltip width="150">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isTotal">总计</div>
                        <div v-else>{{ scope.row.year + "年 " + scope.row.month + "月"
                            }} 合计</div>
                    </template>
                </el-table-column>
                <el-table-column prop="dataTypeName" align="center" label="类型" show-overflow-tooltip />
                <el-table-column prop="voucherCode" align="center" label="凭证号" show-overflow-tooltip />
                <el-table-column prop="remark" align="center" label="摘要" show-overflow-tooltip />
                <el-table-column prop="subject" align="center" label="科目" show-overflow-tooltip />
                <el-table-column prop="voucherMoney" align="center" label="凭证金额" show-overflow-tooltip>

                    <template slot-scope="scope">
                        {{ scope.row.voucherMoney.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="researchMoney" align="center" label="计入研发费用金额" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.researchMoney.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="difference" align="center" label="凭证-研发费=差额" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.difference.toFixed(2) }}
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="800px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="130px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="dataType">
                            <el-select class="w100p" v-model="formData.dataType" clearable placeholder="请选择类型">
                                <el-option v-for="(item, id) in typeObj" :key="id" :label="item" :value="id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="凭证号" prop="voucherCode">
                            <el-input v-model="formData.voucherCode" placeholder="请输入凭证号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年份" prop="year">
                            <!-- <el-input placeholder="请输入年份" class="suffixInput" v-model="formData.year" type="number">

                                <template slot="append">年</template>
                            </el-input> -->
                            <el-date-picker class="w100p" format="yyyy年" value-format="yyyy" v-model="formData.year"
                                type="year" placeholder="选择年">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="月份" prop="month">
                            <!-- <el-input placeholder="请输入月份" class="suffixInput" v-model="formData.month" type="number">

                                <template slot="append">月</template>
                            </el-input> -->
                            <el-select class="w100p" v-model="formData.month" clearable placeholder="请选择月份">
                                <el-option v-for="(item, index) in monthArr" :key="index" :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="摘要" prop="remark">
                            <el-input v-model="formData.remark" placeholder="请输入摘要"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="科目" prop="subject">
                            <el-input placeholder="请输入科目" v-model="formData.subject" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="凭证金额" prop="voucherMoney">
                            <el-input placeholder="请输入凭证金额" @change="voucherMoneyChange" class="suffixInput" v-model="formData.voucherMoney"
                                type="number">

                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计入研发费金额" prop="researchMoney">
                            <el-input placeholder="请输入计入研发费金额" class="suffixInput" v-model="formData.researchMoney"
                                type="number">

                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="转移类型" :visible.sync="showTransfer"
            :destroy-on-close="true" width="500px" center>
            <el-form label-width="130px" :model="transferData" :rules="rules" ref="transferData">
                <el-form-item label="录入类型" prop="fileType">
                    <el-select class="w200" v-model="transferData.fileType" placeholder="请选择类型">
                        <el-option v-for="(title, id) in fileTypeData" :key="id" :label="title" :value="id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="dataType">
                    <el-select class="w200" v-model="transferData.dataType" placeholder="请选择类型">
                        <el-option v-for="(row, id) in typeDatas[transferData.fileType]" :key="id" :label="row.value"
                            :value="row.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="摘要" prop="remark">
                    <el-autocomplete class="w200" v-model="transferData.remark" placeholder="请输入摘要"
                        :fetch-suggestions="searchRemark"></el-autocomplete>
                </el-form-item>
                <el-form-item label="科目" prop="subject">
                    <el-autocomplete class="w200" placeholder="请输入科目" v-model="transferData.subject"
                        :fetch-suggestions="searchSubject" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showTransfer = false">取 消</el-button>
                <el-button type="primary" @click="transferFun" :loading="loadingBtn">转 移</el-button>
            </span>
        </el-dialog>
        <importModel :showDelOldFile="true" :showErrFile="true" :data="{ pcId: this.checkedCompany.pcId, fileType: fileType }" downloadModelType="get"
            :errorDownloadUrl="errorDownloadUrl" :uploadFilesTitle="uploadFilesTitle"
            :uploadFilesVisible="uploadFilesVisible" :uploadModelUrl="uploadModelUrl"
            :downloadModelFileName="downloadModelFileName" :downloadModelUrl="downloadModelUrl"
            @UploadFilesSuccess="filesSuccess" @uploadFilesDialogClose="hideModel" @onConfirmUploadFiles="searchFun" />
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import importModel from "@/components/common/importModel";
import { setProjectMoneyData, getProjectMoneyDataList, delProjectMoneyData } from "@/api/projectMoney";
export default {
    name: "costEnterTable4",
    components: {
        commonTable,
        selectCompanyList,
        importModel
    },
    data() {
        return {
            uploadFilesTitle: "导入资产摊销",
            downloadModelFileName: "资产摊销模板",
            uploadModelUrl: "project/insertAllProjectMoneyData",
            downloadModelUrl: "project/downloadProjectMoney",
            errorDownloadUrl: "project/downloadWordFile?fileName=",
            uploadFilesVisible: false,
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            yearData: [],
            tableData: [],
            listTypeData: [
                {
                    label: "详细",
                    id: false
                },
                {
                    label: "月度",
                    id: true
                }
            ],
            monthData: [
                {
                    label: "全部",
                    id: "all"
                },
                {
                    label: "1月",
                    id: 1
                },
                {
                    label: "2月",
                    id: 2
                },
                {
                    label: "3月",
                    id: 3
                },
                {
                    label: "4月",
                    id: 4
                },
                {
                    label: "5月",
                    id: 5
                },
                {
                    label: "6月",
                    id: 6
                },
                {
                    label: "7月",
                    id: 7
                },
                {
                    label: "8月",
                    id: 8
                },
                {
                    label: "9月",
                    id: 9
                },
                {
                    label: "10月",
                    id: 10
                },
                {
                    label: "11月",
                    id: 11
                },
                {
                    label: "12月",
                    id: 12
                }
            ],
            monthArr: [
                {
                    label: "1月",
                    id: 1
                },
                {
                    label: "2月",
                    id: 2
                },
                {
                    label: "3月",
                    id: 3
                },
                {
                    label: "4月",
                    id: 4
                },
                {
                    label: "5月",
                    id: 5
                },
                {
                    label: "6月",
                    id: 6
                },
                {
                    label: "7月",
                    id: 7
                },
                {
                    label: "8月",
                    id: 8
                },
                {
                    label: "9月",
                    id: 9
                },
                {
                    label: "10月",
                    id: 10
                },
                {
                    label: "11月",
                    id: 11
                },
                {
                    label: "12月",
                    id: 12
                }
            ],
            searchData: { dataType: "all", year: "all", month: "all", isMonth: false },
            typeObj: {
                1: "软件",
                2: "专利",
                3: "非专利"
            },
            remarkObj: {
                1: "软件摊销费用",
                2: "专利摊销费用",
                3: "非专利技术摊销费用",
            },
            subjectObj: {
                1: "无形资产摊销-软件",
                2: "无形资产摊销-专利",
                3: "无形资产摊销-非专利技术",
            },
            checkedCompany: {},
            //模态框配置
            modelTitle: "",
            formData: {
                dataType: "",//数据类型（1：材料，2：燃料，3：动力，4：制造费，5：样品，6：试验费，7：设备费，8：租赁）
                year: "",//年份
                month: "",//月份
                remark: "",//摘要
                researchMoney: "",//	计入研发费金额
                subject: "",//科目
                voucherCode: "",//凭证号
                voucherMoney: "",//凭证金额
            },
            userInfo: this.$store.state.loginRoot.userInfo,
            rules: {
                dataType: {
                    required: true,
                    trigger: "change",
                    message: "请输入类型",
                },
                year: {
                    required: true,
                    trigger: "blur",
                    message: "请输入年份",
                },
                month: {
                    required: true,
                    trigger: "blur",
                    message: "请输入月份",
                },
                remark: {
                    required: true,
                    trigger: "blur",
                    message: "请输入摘要",
                },
                researchMoney: {
                    required: true,
                    trigger: "blur",
                    message: "请输入计入研发费金额",
                },
                subject: {
                    required: true,
                    trigger: "blur",
                    message: "请输入科目",
                },
                voucherCode: {
                    required: true,
                    trigger: "blur",
                    message: "请输入凭证号",
                },
                voucherMoney: {
                    required: true,
                    trigger: "blur",
                    message: "请输入凭证金额",
                }
            },
            formType: "",
            updateId: 0,
            loadingBtn: false,
            fileType: 3,//1：直接投入，2：折旧费用，3：资产摊销，4：新产品设计费，5：其他费用，6：委外技术开发费
            showAllTable: false,
            btnName: "资产摊销",
            //转移配置
            fileTypeData: {
                1: "直接投入",
                2: "折旧费用",
                3: "无形资产摊销",
                4: "新产品设计费",
                5: "其他费用",
                6: "委外技术开发费"
            },
            typeDatas: {
                1: [
                    { id: 1, value: "材料" },
                    { id: 2, value: "燃料" },
                    { id: 3, value: "动力" },
                    { id: 4, value: "制造费" },
                    { id: 5, value: "样品" },
                    { id: 6, value: "试验费" },
                    { id: 7, value: "设备费" },
                    { id: 8, value: "租赁" },
                ],
                2: [
                    { id: 1, value: "研发设备" },
                    { id: 2, value: "研发仪器" },
                ],
                3: [
                    { id: 1, value: "软件" },
                    { id: 2, value: "专利" },
                    { id: 3, value: "非专利" },
                ],
                4: [
                    { id: 1, value: "新产品" },
                    { id: 2, value: "新工艺" },
                    { id: 3, value: "新药临床" },
                    { id: 4, value: "勘探开发技术的现场" },
                ],
                5: [
                    { id: 1, value: "差旅" },
                    { id: 2, value: "办公用品" },
                    { id: 3, value: "会议费" },
                    { id: 4, value: "知识产权" },
                    { id: 5, value: "技术服务费" },
                    { id: 6, value: "医疗附加" },
                ],
                6: [
                    { id: 1, value: "委外开发" },
                    { id: 2, value: "境外开发" },
                ]
            },
            subjectDatas: {
                1: [
                    { value: "直接投入-材料" },
                    { value: "直接投入-燃料" },
                    { value: "直接投入-水电" },
                    { value: "直接投入-制造费" },
                    { value: "直接投入-样品费" },
                    { value: "直接投入-试验费" },
                    { value: "直接投入-设备费" },
                    { value: "直接投入-租赁费" },
                ],
                2: [
                    { value: "折旧费用" },
                    { value: "折旧费用" },
                ],
                3: [
                    { value: "无形资产摊销-软件" },
                    { value: "无形资产摊销-专利" },
                    { value: "无形资产摊销-非专利技术" },
                ],
                4: [
                    { value: "新产品设计费" },
                    { value: "新工艺规程制定费" },
                    { value: "新药临床试验费" },
                    { value: "勘探的现场试验费" },
                ],
                5: [
                    { value: "其他费用-差旅费" },
                    { value: "其他费用-办公用品" },
                    { value: "其他费用-会议费" },
                    { value: "其他费用-知识产权" },
                    { value: "其他费用-技术服务费" },
                    { value: "其他费用-医疗附加" },
                ],
                6: [
                    { value: "境外技术开发费" },
                    { value: "委外技术开发费" },
                ]
            },
            remarkDatas: {
                1: [
                    { value: "研发领用材料" },
                    { value: "研发用的燃料" },
                    { value: "研发用动力费用" },
                    { value: "研发用的制造费" },
                    { value: "研发用样品购买" },
                    { value: "研发项目试验费" },
                    { value: "研发项目设备费" },
                    { value: "研发项目租赁费" },
                ],
                2: [
                    { value: "本月设备折旧费用" },
                    { value: "本月仪器折旧费用" },
                ],
                3: [
                    { value: "软件摊销费用" },
                    { value: "专利摊销费用" },
                    { value: "非专利技术摊销费用" },
                ],
                4: [
                    { value: "新产品设计费" },
                    { value: "新工艺规程制定费" },
                    { value: "新药临床试验费" },
                    { value: "勘探开发技术的现场试验费" },
                ],
                5: [
                    { value: "本月研发差旅费" },
                    { value: "本月研发办公用品" },
                    { value: "会议费用" },
                    { value: "知识产权费用" },
                    { value: "技术服务费用" },
                    { value: "医疗附加费用" },
                ],
                6: [
                    { value: "技术服务费用" },
                    { value: "技术服务费用" },
                ]
            },
            transferData: {
                mdId:0,
                fileType: "",
                dataType: "",
                remark: "",
                subject: "",
            },
            showTransfer: false,
        };
    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.downloadModelUrl = "project/downloadProjectMoney?fileType=" + this.fileType;
        if(this.$route.query.year){
            this.searchData.year=this.$route.query.year;
        }
        //获取年份
        this.getYearData();
        this.getList();
    },
    methods: {
        /**@method 摘要提示*/
        searchRemark(queryString, cb) {
            let data = this.remarkDatas[this.transferData.fileType];
            cb(data);
        },
        /**@method 科目提示*/
        searchSubject(queryString, cb) {
            let data = this.subjectDatas[this.transferData.fileType];
            cb(data);
        },
        /**@method 转移 */
        transferFun() {
            this.$refs.transferData.validate((valid) => {
                if (valid) {
                    this.loadingBtn = true;
                    let message = "转移完成";
                    setProjectMoneyData(this.transferData).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message.success(message)
                            this.searchFun();
                            this.showTransfer = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            })
        },
        /**@method 显示转移模态框 */
        showTransferFun(row) {
            this.transferData = {
                mdId: row.mdId,
                dataType: "",
                fileType: String(this.fileType),
                remark: "",
                subject: "",
            };
            this.showTransfer = true;
        },
        setBtnName() {
            if (this.searchData.dataType == 'all') {
                this.btnName = "资产摊销"
            } else {
                this.btnName = this.typeObj[this.searchData.dataType];
            }
            this.uploadFilesTitle = "导入" + this.btnName;
            this.downloadModelFileName = this.btnName + "模板";
        },
        voucherMoneyChange() {
            if (this.formData.voucherMoney) {
                this.formData.researchMoney = this.formData.researchMoney ? this.formData.researchMoney : this.formData.voucherMoney;
            }
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));


            this.searchFun();
        },
        filesSuccess() {


            this.searchFun();
            // this.uploadFilesVisible = false;
        },
        hideModel() {
            this.uploadFilesVisible = false;
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData=[];
let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
this. yearData= yearData;
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage=1;
            this.setBtnName();
            this.getList();
        },
        addUpdate() {
            //修改添加
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        pcId: this.checkedCompany.pcId,
                        fileType: this.fileType,
                    };
                    let message = "录入成功";
                    if (this.updateId) {
                        params.mdId = this.updateId;
                        message = "修改成功"
                    }
                    this.loadingBtn = true;
                    setProjectMoneyData(params).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message.success(message)


                            this.searchFun();
                            this.showModel = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                fileType: this.fileType,
                sortByMonth: true,
                sortByYear: true,
                monthDesc: false,
                isMonth: this.searchData.isMonth
            };
            this.showAllTable = !this.searchData.isMonth;
            if (this.searchData.isMonth) {
                delete params.pageSize;
                delete params.pageNum;
            }
            if (this.searchData.month != 'all') {
                params.month = this.searchData.month;
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            if (this.searchData.dataType != 'all') {
                params.dataType = this.searchData.dataType
            }
            if (this.searchData.year != 'all') {
                params.year = this.searchData.year
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyDataList(params);
                this.loading = false;
                const { data } = result;
                let tableData = [];
                if (this.searchData.isMonth) {
                    for (let row of data) {
                        row.dataTypeName = this.typeObj[row.dataType];
                        row.difference = Math.abs(row.voucherMoney - row.researchMoney);
                        tableData.push(row)
                    }
                } else {
                    for (let row of data.pageInfo.list) {
                        row.dataTypeName = this.typeObj[row.dataType];
                        row.difference = Math.abs(row.voucherMoney - row.researchMoney);
                        tableData.push(row)
                    }
                    this.total = data.pageInfo.total;
                }
                this.tableData = tableData;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示录入模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "录入"+this.btnName;
            this.formType = "add";
            this.formData = {
                dataType: this.searchData.dataType!='all'?this.searchData.dataType:"",//数据类型（1：材料，2：燃料，3：动力，4：制造费，5：样品，6：试验费，7：设备费，8：租赁）
                year: this.searchData.year!='all'?this.searchData.year:String(new Date().getFullYear()),//年份
                month: new Date().getMonth() + 1,//月份
                remark: this.searchData.dataType != 'all' ? this.remarkObj[this.searchData.dataType] : "",//摘要
                researchMoney: "",//	计入研发费金额
                subject: this.searchData.dataType != 'all' ? this.subjectObj[this.searchData.dataType] : "",//科目
                voucherCode: "记-",//凭证号
                voucherMoney: "",//凭证金额
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑"+this.btnName;
            this.formType = "update";
            this.updateId = val.mdId;
            this.formData = {
                dataType: String(val.dataType),//数据类型（1：材料，2：燃料，3：动力，4：制造费，5：样品，6：试验费，7：设备费，8：租赁）
                year: String(val.year),//年份
                month: val.month,//月份
                remark: val.remark,//摘要
                researchMoney: val.researchMoney,//	计入研发费金额
                subject: val.subject,//科目
                voucherCode: val.voucherCode,//凭证号
                voucherMoney: val.voucherMoney,//凭证金额
            };
        },
        delData(row) {
            this.$confirm("确定要继续删除这条"+this.btnName, "删除"+this.btnName+"数据", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    mdId: row.mdId
                }
                delProjectMoneyData(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.showModel = false;


                        this.searchFun();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>