<template>
    <div class="view pa24">
        <div class="flex-a-b-c mb20">
            <div class="selectCompany flex-a-c hover_pointer " @click="showCompanyList"><i
                    class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName
                    }}</span>
                <i class="el-icon-caret-bottom  fs12 ml5"></i>
            </div>
            <div>
                <el-button class="mr20 h34" type="primary" @click="showUploadVoucherNo">凭证号替换</el-button>
                <el-button class="enterBtn h34" type="primary" @click="dataInput">原始表导入</el-button>
            </div>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle curPointer"></i>
                    <div class="costContainer curPointer" @click="hrefFun('costEnterTable1')">
                        <div class="costTitle mb10">
                            <div class="cTitle">一、人员人工费用</div>
                            <el-button class="enterBtn h34" @click="hrefFun('costEnterTable1')"
                                type="primary">数据录入</el-button>
                        </div>
                        <div class="costContent">
                            包含“工资薪金”、“五险一金”、“外聘研发人员的劳务费用”
                        </div>
                    </div>
                </div>
                <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle curPointer"></i>
                    <div class="costContainer curPointer" @click="hrefFun('costEnterTable2')">
                        <div class="costTitle mb10">
                            <div class="cTitle">二、直接投入费用</div>
                            <el-button class="enterBtn h34" type="primary"
                                @click="hrefFun('costEnterTable2')">数据录入</el-button>
                        </div>
                        <div class="costContent">
                            包含“材料”、“燃料”、“动力费用”、“用于中间试验和产品试制的模具、工艺装备开发及制造费”、“用于不构成固定资产的样品、样机及一般测试手段购置费”、“用于试制产品的检验费”、“用于研发活动的仪器、设备的运行维护、调
                            整、检验、维修等费用”、“通过经营租赁方式租入的用于研发活动的仪器、设备租赁费”。
                        </div>
                    </div>
                </div>
                <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle curPointer"></i>
                    <div class="costContainer curPointer" @click="hrefFun('costEnterTable3')">
                        <div class="costTitle mb10">
                            <div class="cTitle">三、折旧费用</div>
                            <el-button class="enterBtn h34" type="primary"
                                @click="hrefFun('costEnterTable3')">数据录入</el-button>
                        </div>
                        <div class="costContent">
                            包含“用于研发活动的仪器的折旧费”、“用于研发活动的设备的折旧费”。
                        </div>
                    </div>
                </div>
                <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle curPointer"></i>
                    <div class="costContainer curPointer" @click="hrefFun('costEnterTable4')">
                        <div class="costTitle mb10">
                            <div class="cTitle">四、无形资产摊销</div>
                            <el-button class="enterBtn h34" type="primary"
                                @click="hrefFun('costEnterTable4')">数据录入</el-button>
                        </div>
                        <div class="costContent">
                            包含“用于研发活动的软件的摊销费用”、“用于研发活动的专利权的摊销费用”、“用于研发活动的非专利技术（包括许可证、专有技术、设计和计算方法等）的摊销费用”。
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle curPointer"></i>
                    <div class="costContainer curPointer" @click="hrefFun('costEnterTable5')">
                        <div class="costTitle mb10">
                            <div class="cTitle">五、新产品设计费等</div>
                            <el-button class="enterBtn h34" type="primary"
                                @click="hrefFun('costEnterTable5')">数据录入</el-button>
                        </div>
                        <div class="costContent">
                            包含“新产品设计费”、“新工艺规程制定费”、“新药研制的临床试验费”、“勘探开发技术的现场试验费”。
                        </div>
                    </div>
                </div>
                <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle curPointer"></i>
                    <div class="costContainer curPointer" @click="hrefFun('costEnterTable6')">
                        <div class="costTitle mb10">
                            <div class="cTitle">六、其他相关费用</div>
                            <el-button class="enterBtn h34" type="primary"
                                @click="hrefFun('costEnterTable6')">数据录入</el-button>
                        </div>
                        <div class="costContent">
                            专门用于研发活动的可以按照相关凭证登记，共用的应当按照《费用分配表》等类似分配表汇总登记；费用明细名称应当据实填写，主要包含：技术图书资料费、资料翻译费、专家咨询费、高新科技研发保险费，研发成果的检索、分析、评议、论证、鉴定、评审、评估、验收费用，知识产权的申请费、注册费、代理费，差旅费、会议费等；可以按照需要增加费用明细。
                        </div>
                    </div>
                </div>
                <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle curPointer"></i>
                    <div class="costContainer curPointer" @click="hrefFun('costEnterTable7')">
                        <div class="costTitle mb10">
                            <div class="cTitle">
                                七、委托外部机构或个人进行研发活动所发生的费用
                            </div>
                            <el-button class="enterBtn h34" type="primary"
                                @click="hrefFun('costEnterTable7')">数据录入</el-button>
                        </div>
                        <div class="costContent">
                            委托方与受托方存在关联关系选项：应当按照《企业（委托）研究开发项目计划书》和《技术开发（委托）合同》等类似文件选择存在、不存在；关联关系应当按照税法的相关规定判定；存在关联关系的，应当按照受托方向委托方提供研发项目费用支出明细情况分别登记在序号1～6对应费用明细中；不存在关联关系的，应当按照相关凭证登记在序号7中。
                        </div>
                    </div>
                </div>
                <!-- <div class="link">
                    <el-image class="costIcon icon-user-following"
                        src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/ic_web_about_selected.png"></el-image>
                    <i class="triangle"></i>
                    <div class="costContainer">
                        <div class="costTitle mb10">
                            <div class="cTitle curPointer" @click="showCompanyList">
                                {{ checkedCompany.companyName }}
                            </div>
                            <el-button class="enterBtn h34" type="primary" @click="dataInput">原始表导入</el-button>
                        </div>
                    </div>
                </div> -->
            </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="12" v-for="(data,i) in CostInfoType" :key="i">
            <div class="link" v-for="(v,k) in data" :key="k">
              <div class="costIcon" :class="v.icon"></div>
              <i class="triangle curPointer"></i>
              <div class="costContainer curPointer" @click="hrefFun('costEnterTable2')">
                <div class="costTitle mb10">
                  <div class="cTitle" v-text="v.Name"></div>
                  <el-button class="enterBtn h34" type="primary">数据录入</el-button>
                </div>
                <div class="costContent" v-text="v.introduce">
                </div>
              </div>
            </div>
          </el-col>
        </el-row> -->
        <importModel :showDelOldFile="true" :data="{ pcId: this.checkedCompany.pcId, companyId: userInfo.companyId }"
            downloadModelType="get" :uploadFilesTitle="uploadFilesTitle" :uploadFilesVisible="uploadFilesVisible"
            :uploadModelUrl="uploadModelUrl" :downloadModelFileName="downloadModelFileName"
            :downloadModelUrl="downloadModelUrl" @uploadFilesDialogClose="hideModel" />
        <importModel :data="{ pcId: this.checkedCompany.pcId, companyId: userInfo.companyId }" downloadModelType="get"
            :uploadFilesTitle="uploadVoucherNoModelTitle" :uploadFilesVisible="uploadVoucherNoVisible"
            :uploadModelUrl="uploadVoucherNoModelUrl" :downloadModelFileName="downloadVoucherNoModelName"
            :downloadModelUrl="downloadVoucherNoModelUrl" @uploadFilesDialogClose="hideVoucherNoModel" />
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
        <el-dialog title="提示" :visible.sync="showCostEnter" :fullscreen="true" top="40px" width="30%"
            :close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" :modal="false">
            <div class="selectProject flex-a-b-c curPointer" @click="showCostEnter = false">
                <div class="flex-a-c ">
                    <i class="el-icon-help fs12 mr5"></i>
                    <span class="fs10">{{ costEnterTitle }}</span>
                </div>
                <div class="el-icon-close fs14 closeWordModel fwbold"></div>
            </div>
            <div>
                <component :is="costEnterComponent" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
import importModel from "@/components/common/importModel";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import costEnterTable1 from "./components/costEnterTable1";
import costEnterTable2 from "./components/costEnterTable2";
import costEnterTable3 from "./components/costEnterTable3";
import costEnterTable4 from "./components/costEnterTable4";
import costEnterTable5 from "./components/costEnterTable5";
import costEnterTable6 from "./components/costEnterTable6";
import costEnterTable7 from "./components/costEnterTable7";
export default {
    name: "costEnter",
    data() {
        return {
            userInfo: this.$store.state.loginRoot.userInfo,
            checkedCompany: {},

            uploadFilesTitle: "导入原始表",
            downloadModelFileName: "原始表模板",
            uploadModelUrl: "project/insertProjectOriginal",
            downloadModelUrl: "project/downloadProjectMoney?isOriginal=true&fileType=0",
            uploadFilesVisible: false,
            //凭证号导入配置
            uploadVoucherNoModelTitle: "导入凭证号",
            downloadVoucherNoModelName: "凭证号模板",
            uploadVoucherNoVisible: false,
            uploadVoucherNoModelUrl: "project/insertProjectVoucherCode",
            downloadVoucherNoModelUrl: "project/downloadProjectMoney?isVoucherCode=true&fileType=0",
            //数据录入配置
            showCostEnter: false,
            costEnterTitle: "",
            costEnterComponent: "",

        };
    },
    components: {
        importModel,
        selectCompanyList,
        costEnterTable1,
        costEnterTable2,
        costEnterTable3,
        costEnterTable4,
        costEnterTable5,
        costEnterTable6,
        costEnterTable7,
    },
    filters: {

    },
    created() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
    },
    methods: {
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
        },
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        hideModel() {
            this.uploadFilesVisible = false;
        },
        hideVoucherNoModel() {
            this.uploadVoucherNoVisible = false;
        },
        hrefFun(url) {
            let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            if (!checkedCompany) {
                this.showCompanyList();
                return;
            }
            this.costEnterComponent = url;
            if (url == 'costEnterTable1') {
                this.costEnterTitle = "人员人工费用"
            } else if (url == 'costEnterTable2') {
                this.costEnterTitle = "直接投入费用"
            } else if (url == 'costEnterTable3') {
                this.costEnterTitle = "折旧费用"
            } else if (url == 'costEnterTable4') {
                this.costEnterTitle = "无形资产摊销"
            } else if (url == 'costEnterTable5') {
                this.costEnterTitle = "新产品设计费等"
            } else if (url == 'costEnterTable6') {
                this.costEnterTitle = "其他相关费用"
            } else if (url == 'costEnterTable7') {
                this.costEnterTitle = "委托外部机构或个人进行研发活动所发生的费用"
            }
            this.showCostEnter = true;
            // this.$router.push("/" + url);
        },
        showUploadVoucherNo() {
            if (!this.checkedCompany.pcId) {
                this.$message.error("请选择录入的公司");
                this.showCompanyList();
                return;
            }
            this.uploadVoucherNoVisible = true;
        },
        dataInput() {
            if (!this.checkedCompany.pcId) {
                this.$message.error("请选择录入的公司");
                this.showCompanyList();
                return;
            }
            this.uploadFilesVisible = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #2a579a;
    cursor: pointer;
}

.selectProject {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    // color: #51CBCD;

    .el-icon-close {
        cursor: pointer;
    }
}

.costIcon {
    font-size: 34px;
    background: #f5f6fa;
    padding: 18px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: #2a579a;
    z-index: 2;
    position: relative;
}

.link:before {
    background: #f5f6fa;
    margin-left: 38px;
    width: 4px;
    top: 0px;
    bottom: 0px;
    content: "";
    position: absolute;
    z-index: 1;
}

.link {
    display: flex;
}

.costContainer {
    padding: 20px;
    background: #f5f6fa;
    margin-bottom: 20px;
    width: calc(100% - 128px);
}

.costContainer>.costTitle {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.costContainer>.costTitle>.cTitle {
    color: #2a579a;
    font-size: 16px;
    font-weight: 600;
    min-height: 34px;
    line-height: 34px;
    padding-right: 10px;
}

.costContainer>.costContent {
    color: #95a5a6;
}

.enterBtn {
    background-color: #4484FD;
    border-color: #4484FD;
}

.triangle {
    border: 14px solid transparent;
    width: 28px;
    height: 28px;
    border-right-color: #f5f6fa;
    margin-top: 26px;
}
</style>